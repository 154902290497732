import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_ENABLE_RATINGS_IN_VIEWER } from '@wix/communities-blog-experiments';
import { getIsPostRatingEnabled } from '../../../common/selectors/app-settings-selectors';
import { getPostBySlug } from '../../../common/selectors/post-selectors';
import { updatePostsRatings } from '../../../common/store/post-ratings/post-ratings-actions';
import type {
  AppStore,
  ControllerConfig,
  FlowAPI,
} from '../../../common/types';

type Params = {
  controllerConfig: ControllerConfig;
  store: AppStore;
  flowAPI: FlowAPI;
  loadSeo?: boolean;
};

export async function initWixCommentsController({
  controllerConfig,
  store,
  flowAPI,
  loadSeo,
}: Params) {
  const initializeCommentsController = (
    await import(
      /* webpackChunkName: "wix-comments-controller" */ './wix-comments-controller'
    )
  ).default;
  const wixCommentsApi = await initializeCommentsController({
    controllerConfig,
    httpClient: flowAPI.httpClient,
    useRicosNext: true,
    loadSeo: !!loadSeo,
  });

  if (loadSeo) {
    const post = getPostBySlug(
      store.getState(),
      controllerConfig.wixCodeApi.location.path[1], // We are passing resolved wixCodeApi from create-controller.ts
    );
    const resourceId = post?.internalId || undefined;
    if (!resourceId) {
      return wixCommentsApi;
    }

    // fetchComments returns a promise (not void).
    const fetchComments = wixCommentsApi.fetchComments({
      resourceId,
      ctxFields: {
        contextId: resourceId,
        contextType: 'postId',
      },
    }) as unknown as Promise<void>;
    await fetchComments;

    // bindStateToSetProps returns a promise (not void).
    const bindStateToSetProps =
      wixCommentsApi.bindStateToSetProps() as unknown as Promise<void>;
    await bindStateToSetProps;
  } else {
    const state = store.getState();
    const withRatings =
      getIsPostRatingEnabled(state) &&
      isExperimentEnabled(state, EXPERIMENT_ENABLE_RATINGS_IN_VIEWER);

    if (withRatings) {
      wixCommentsApi.watch.ratings.onChange((state) => {
        store.dispatch(updatePostsRatings(state));
      });
    }
  }

  return wixCommentsApi;
}
