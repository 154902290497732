import { sortBy } from 'lodash';
import { FETCH_CATEGORIES_SUCCESS } from './fetch-categories';

const SORT_BY = 'rank';

const categories = (state = [], { type, payload } = {}) => {
  switch (type) {
    case FETCH_CATEGORIES_SUCCESS:
      return sortBy(payload, SORT_BY);

    default:
      return state;
  }
};

export default categories;
